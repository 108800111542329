<template>
    <div>
        <vue-header title="健康红包" :fixed="true"/>
        <div v-if="info && info.amount_id">
            <div class="redpacket-title">了解健康红包</div>
            <div class="redpacket-box flex flex-x">
                <div class="packet-f flex">
                    <div class="packet" :class="{active:type==1}" @click="()=>typeFun(1)">私发红包</div>
                    <div class="packet" :class="{active:type==2}" @click="()=>typeFun(2)">群发红包</div>
                </div>
            </div>
            <div class="packet-sz">
                <div class="packet-kp flex">
                    <div class="flex-1"></div>
                    <div v-if="type==1" @click="()=>titleFun('私发红包','单独发给任意一位好友，每个红包仅含一次中医智能健康评估，使用后该红包失效。')">什么是私发红包<van-icon name="question-o" /></div>
                    <div v-if="type==2" @click="()=>titleFun('群发红包','可发到好友群，每个红包可使用的中医智能健康评估次数为您设置的“总检测次数”，不限每个用户的领取次数，次数用完后该红包失效。')">什么是群发红包<van-icon name="question-o" /></div>
                </div>
                <div class="red-bot" v-if="type==2">
                    <div class="red-sz">红包设置</div>
                    <div class="red-sz-num flex">
                        <div @click="()=>titleFun('总检测次数','总检测次数等于AI中医健康评估次数。')">总检测次数<van-icon name="question-o" /></div>
                        <div class="flex-1">
                            <input type="tel" v-model="test_count" />
                        </div>
                        <div>次</div>
                    </div>
                </div>
                <div class="red-bot">
                    <div class="red-sz">祝福语</div>
                    <div  class="red-sz-textarea">
                        <textarea type="text" v-model.trim="blessingText"></textarea>
                        <div class="sz-right">{{blessing.length}}/25</div>
                    </div>
                </div>
                <div class="red-ky flex">
                    <router-link :to="`/`" class="flex-1">
                        <div class="red-ky-open flex flex-x flex-y">
                            <img src="../../../../images/redEnvelope/operation.png"/>
                        </div>
                        <div>操作指南</div>
                    </router-link>
                    <a href="/redEnvelope/manage" class="flex-1">
                        <div class="red-ky-open flex flex-x flex-y">
                            <img src="../../../../images/redEnvelope/receive.png"/>
                        </div>
                        <div>领取记录</div>
                    </a>
                    <router-link :to="`/profile?red=true`" class="flex-1">
                        <div class="red-ky-open flex flex-x flex-y">
                            <img src="../../../../images/redEnvelope/card.png"/>
                        </div>
                        <div>个人名片</div>
                    </router-link>
                </div>
            </div>
            <div class="red-tis">小发现：进行3~6次健康评估后，</div>
            <div class="red-tis">超过50%的用户开始关注自己的健康状况！</div>
            <div class="flex flex-x">
                <div class="give" v-if="(type==1 && blessing.length>0)||(type==2 && blessing.length>0 && test_count>0)" @click="giveFun">立即赠送</div>
                <div class="give no-give" v-else>立即赠送</div>
            </div>
            <div class="alert" v-if="shareBool" @click="()=>this.shareBool=false">
                <div class="alert-top">
                    <img src="../../../../images/share.png" class="alert-share" />
                </div>
            </div>
        </div>
        <div v-else-if="info && !info.amount_id" class="tit-title flex flex-y flex-x">
            <div>
                <div>您还不是业务员，无法使用此功能!</div>
                <div>请联系您的健康咨询师</div>
            </div>
        </div>
    </div>
</template>
<script>
    import vueHeader from '@/components/header';
    import { mapState, mapActions, mapMutations } from "vuex";
    import { Dialog } from 'vant';
    export default {
        name: 'send',
        components:{
            vueHeader
        },
        data(){ 
            return {
                type:1,
                test_count:1,
                blessing:'身体健康，万事如意！',
                shareBool:false
            }
        },
        created:function(){
            this.axiosMain({});
            this.configData();
        },
        computed:{
            blessingText:{
                get() {
                    return this.blessing;
                },
                set(value){
                    if(value.length<=25){
                        this.blessing = value;
                    }
                }
            },
            ...mapState('profile',['info']),
        },
        methods: {
            typeFun(v){
                this.type=v;
            },
            titleFun(title,text){
                Dialog.alert({
                    title: title,
                    message: text,
                    theme: 'round-button',
                })
            },
            giveFun(){
                let bool = true
                if(!this.info.amount_id){
                    bool=false;
                }
                if(!this.info.phone||!this.info.company_name||!(this.info.nikeName||this.info.name)){
                    bool=false;
                    Dialog.alert({
                        message: '请先完善个人名片',
                        theme: 'round-button',
                    }).then(() => {
                        this.$router.push('/profile?red=true');
                    });
                }
                let _this = this;
                if(bool){
                    this.axiosRed({
                        data:{
                            type:this.type,
                            test_count:this.test_count,
                            blessing:this.blessing
                        },
                        fun:(token)=>{
                            Dialog.confirm({
                                title: '温馨提示',
                                message: '健康红包一旦赠送成功，将无法撤回和修改，请确认无误后再赠送哦!',
                                confirmButtonText:'继续赠送',
                                cancelButtonText:'我再想想',
                            })
                            .then(() => {
                                _this.axiosHongbaoSend({
                                    data:{
                                        hongbao_code:token
                                    },
                                    fun:()=>{
                                        _this.shareBool=true;
                                        let wx  = window.wx;
                                        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                                            wx.updateAppMessageShareData({
                                                title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                                                desc: '送您一张AI中医智能评估卷,随时掌握健康状态！', // 分享描述
                                                link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${token}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                                imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                                                success: function () {} 
                                            });
                                            wx.updateTimelineShareData({ 
                                                title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                                                link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${token}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                                imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                                                success: function () {}
                                            });
                                        });
                                    }
                                })
                            }).catch(() => {
                                _this.axiosHongbaoCancel({
                                    data:{
                                        hongbao_code:token
                                    }
                                })
                            });
                        }
                    })
                }
            },
            ...mapActions('redEnvelope',['axiosRed','axiosHongbaoSend','axiosHongbaoCancel']),
            ...mapActions('profile',['axiosMain']),
            ...mapActions('user',['configData'])
        }
        
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'send';
</style>